import React from 'react';
import Breadcrumbs from "../breadcrumbs";
import { connect } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import NoveltyCard from '../novelty-card';
import ContentLoader from "react-content-loader"

import '../../../node_modules/animate.css/animate.css';

import {updateFiltersNovelties,getFiltersNoveltiesAction,clearNoveltyAction} from "../../redux/noveltiesDucks";

import { useEffect, useState } from 'react';

const Main = ({loading_novelties,loading_tags,loading_grid,novelties,grid_novelties,tags,dispatch}) => {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
          }
    }`)

    const [listActives,setListActive] = useState([])

    const toggleTag = (id) => {
        if(listActives.find(e => e === id)){
            return setListActive(listActives.filter(e => e !== id))
        }
        return setListActive([...listActives,id]);
    } 

    useEffect(() => {
        dispatch(updateFiltersNovelties(listActives));
    }, [listActives])

    useEffect(() => {
        dispatch(clearNoveltyAction());
    } ,[])


    return(
        <section className="novelty-main">
            <div className="container-fluid">
                <div className="row mt-lg-4 align-items-center">
                    <div className="first_div col-12 align-items-center"> 
                        <div className="col-12">
                            <Breadcrumbs
                                props={[
                                { name: realEstate.name, route: "/", location: "" },
                                { name: "Novedades", route:   "", location: ""},
                                ]}
                            />
                        </div>
                        <div className="col-10 col-lg-6">
                            <h1 className='title'>Novedades</h1>
                            <h2 className="subtitle">Quis autem vel eum iure reprehenderit qui in eum ea voluptate autem velit.</h2>
                        </div>   
                    </div>
                    <div className="second_div col-12">
                        <div className="div_search d-flex p-3 justify-content-between">
                            <i className='d-none icon-price-tag me-3 d-md-flex'></i>
                            <div className='pills_search d-flex justify-content-between'>
                                <div className='div_pills flex-wrap'>
                                        {listActives.map((element, index) =>(
                                            <span className='animate__animated animate__fadeInRight pill btn btn-primary me-2 mt-2 mt-lg-0'>{tags.find(item => item.id === element).name}<i onClick={() => toggleTag(element)} className='icon-close-light'></i></span>
                                        ))}
                                    {/* pill */}
                                    {/* Fin pill */}
                                </div>
                                <div className='div_buttons d-flex'>
                                    <button className='btn-clear me-3 d-none d-md-block cursor-pointer' onClick={() => setListActive([])}>Limpiar</button>
                                    <button className='btn btn-red btn-share d-none d-lg-flex cursor-pointer' onClick={() => dispatch(getFiltersNoveltiesAction(listActives))}  >BUSCAR <i className='icon-search ms-3'></i></button>
                                    <button className='btn btn-red btn-share-icon d-lg-none cursor-pointer' onClick={() => dispatch(getFiltersNoveltiesAction(listActives))}  ><i className='icon-search '></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="div_etiquetas flex-wrap">
                            {loading_tags ? 
                                [1,2,3].map(count => (
                                    <div className='me-3 mb-2 d-inline-block'>
                                        <ContentLoader 
                                        speed={2}
                                        width={"10rem"}
                                        height={"3.5rem"}
                                        viewBox="0 0 2rem 5rem"
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#ecebeb"
                                        >
                                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" /> 
                                        </ContentLoader> 
                                    </div>
                                ))
                            : 
                                tags.map((tag,index) => (
                                    listActives.find(e => e === tag.id) ? '' :
                                    <span key={index} onClick={() => toggleTag(tag.id)} className='animate__animated animate__fadeInDown cursor-pointer pill btn btn-primary me-2 mt-2 mt-lg-0'>{tag.name}</span>
                                ))}
                        </div>
                    </div>
                    <div className="div_novedades row">
                        <div className="col-12 div_card mt-5">
                            <div className="row">
                                
                        {
                            loading_grid ?
                                <>
                                    {[1,2,3,4].map(count => (
                                        <div className="col-lg-6 mb-4">
                                            <ContentLoader 
                                                speed={2}
                                                width={"100%"}
                                                height={"50vh"}
                                                viewBox="0 0 100% 50vh"
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#ecebeb"
                                            >
                                                <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" /> 
                                            </ContentLoader>
                                        </div>
                                    ))}
                                </>
                            :
                                grid_novelties.length > 0 ?
                                    grid_novelties.map((novelty,index) => (
                                        <div className="mb-4 col-lg-6">
                                            <NoveltyCard novelty={novelty} />
                                        </div>
                                    ))
                                    :
                                    <h5 className='text-center mb-5'>No hay resultados para esta busqueda</h5>
                        }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
} 

export default connect(state => ({
    loading_novelties: state.novelties.loading_novelties,
    loading_grid: state.novelties.loading_grid,
    loading_tags: state.novelties.loading_tags,
    novelties: state.novelties.novelties,
    grid_novelties: state.novelties.grid_novelties,
    tags: state.novelties.tags
  }),null)(Main);